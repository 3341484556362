import type { ReactElement } from 'react';
import { useTranslation } from 'next-i18next';
import { S } from './styles';
import type { ProductSustainabilityTraceability as ProductSustainabilityTraceabilitySchema } from '../../../amplienceTypes/schemas/imported/product-sustainability-traceability-schema';
import { PreferredMaterials } from './PreferredMaterials';
import { FactoryList } from './FactoryList';
import { Circularity } from './Circularity';
import { PlasticMicroFiber } from './PlasticMicroFiber';
import { usePDPContext } from '../../../utilities/context/static/PDPContext';

export const ProductSustainabilityTraceability = (
  schemaData: ProductSustainabilityTraceabilitySchema
): ReactElement => {
  const {
    product: { isGiftCard },
  } = usePDPContext();
  const { t } = useTranslation('pdp', { keyPrefix: 'productSustainabilityTraceability' });

  const { title, preferredMaterials, circularity, factoryList, plasticMicroFiber } = schemaData;

  if (isGiftCard) {
    return <></>;
  }

  return (
    <div data-testid="sustainability-traceability" id="sustainability-traceability">
      <S.AccordionTitle
        variant="h1"
        component="h2"
        data-testid="sustainability-traceability-title"
        data-accordion-title
      >
        {title ?? t('title')}
      </S.AccordionTitle>
      <PreferredMaterials schemaData={preferredMaterials} />
      <Circularity schemaData={circularity} />
      <FactoryList schemaData={factoryList} />
      <PlasticMicroFiber schemaData={plasticMicroFiber} />
    </div>
  );
};
